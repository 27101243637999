export enum UPDATE_FREQUENCY{
    'DAILY',
    'WEEKLY',
    'FORTNIGHTLY'
}

export const REST_API_NAME='api9d16f874'

export const REST_URLS={
    JOBS:{
        list:'/jobs'
    }
}