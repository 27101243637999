// import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import Select from 'react-select';

import { RWebShare } from "react-web-share";

import JobCard from './JobCard.tsx'


import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBusinessTime, faCheckSquare, faCoffee, faLaptopHouse, faMapMarkerAlt, faRupeeSign, faShareAlt, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Jobs from './Jobs';
import { Route, Routes, useLocation } from 'react-router-dom';
import Dashboard from './Dashboard';

import bootstrap from 'bootstrap'


library.add(faCheckSquare, faCoffee, faRupeeSign, faMapMarkerAlt, faBusinessTime, faSun, faLaptopHouse, faShareAlt)

function App() {

  const [jobs, setJobs] = useState([])
  const [options, setOptions] = useState([]);
  const [lang, setLang] = useState([]);
  const [tags, setTags] = useState([]);

  // useEffect(() => {
  //   API.get('api9d16f874', '/jobs/', {})
  //     .then(r => setJobs(r.records))
  // }, [])

  const JOB_TAGS = [
    { label: 'IT', value: 'IT' },
    { label: 'Non IT', value: 'NonIT' },
    { label: 'BPO', value: 'BPO' },
    { label: 'Voice', value: 'Voice' },
    { label: 'Non Voice', value: 'NonVoice' },
    { label: 'International Voice', value: 'InternationalVoice' },
    { label: 'Semi Voice', value: 'SemiVoice' },

    { label: 'Work From Home Until Further Notice', value: 'WFH' },
    { label: 'Work From Office', value: 'WFO' },
    { label: 'Hybrid Work Model', value: 'HybridWorkModel' },

    { label: 'Tech Support', value: 'TechSupport' },
    { label: 'Customer Support', value: 'CustomerSupport' },

    { label: 'Interior Designer', value: 'InteriorDesigner' },
    { label: 'Coding Teacher', value: 'CodingTeacher' },
    { label: 'Healthcare', value: 'Healthcare' },
    { label: 'Retail', value: 'Retail' },
    { label: 'Front Office Executive', value: 'FrontOfficeExecutive' },
    { label: 'Receptionist', value: 'Receptionist' },

    { label: 'Day Shift', value: 'DayShift' },
    { label: 'Night Shift', value: 'NightShift' },
    { label: 'US Shift', value: 'USShift' },
    { label: 'UK Shift', value: 'UKShift' },
    { label: 'Australia Shift', value: 'AustraliaShift' },
    { label: 'Rotational Shift', value: 'RotationalShift' },
    { label: 'Rotational Off', value: 'RotationalOff' },

    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Kolkata', value: 'Kolkata' },
    { label: 'Gurgaon', value: 'Gurgaon' },


    { label: 'Sales International & Domestic', value: 'Sales' },
    { label: 'Inside Sales, Pre Sales, Soft Sales', value: 'InsideSales' },
    { label: 'Field Sales', value: 'FieldSales' },
    { label: 'Cold Calling Sales', value: 'ColdCallingSales' },




    { label: 'Above 40 Years', value: 'Above40years' },
    { label: 'Crypto Currency', value: 'CryptoCurrency' },
    { label: 'Graduates Only', value: 'GraduatesOnly' },
    { label: 'Graduates', value: 'Graduates' },
    { label: 'Graduate With Experience', value: 'GraduateWithExperience' },
    { label: 'Under Graduates', value: 'UnderGraduates' },
    { label: 'Under Graduate Fresher', value: 'UnderGraduateFresher' },
    { label: 'Under Graduate With Experience', value: 'UnderGraduateExperienced' },
    { label: 'Fresher', value: 'Fresher' },
    { label: '10th Pass', value: '10thPass' },
    { label: '12th Pass', value: '12thPass' },
    { label: 'Weekend Off', value: 'WeekendOff' },
    { label: 'Domestic Process', value: 'DomesticProcess' },
    { label: 'Healthcare', value: 'Healthcare' },


    { label: 'No Career Gaps', value: 'NoGaps' },

    { label: 'Hindi Mandatory', value: 'Hindi' },
    { label: 'Gujarati Mandatory', value: 'Gujarati' },
    { label: 'Kannada Mandatory', value: 'Kannada' },
    { label: 'Malayalam Mandatory', value: 'Malayalam' },
    { label: 'Tamil Mandatory', value: 'Tamil' },
    { label: 'Telugu Mandatory', value: 'Telugu' },
    { label: 'Arabic Mandatory', value: 'Arabic' },

    { label: 'Regional Languages', value: 'RegionalLanguages' },

    { label: 'Laptop Mandatory', value: 'LaptopMandatory' },
    { label: 'No BE or Btech', value: 'NoBEorBtech' },

    { label: 'Females Only', value: 'FemalesOnly' },

    { label: 'Ops Managers', value: 'OpsManager' },
    { label: 'Team Leaders', value: 'TeamLeader' },

    { label: 'Lateral/ Above Agent Level Roles', value: 'Lateral' },

    { label: 'HR & Admin Roles', value: 'HRAndADMIN' },
  ]

  const JOB_LANGS = [
    { label: 'Bangalore', value: 'Bangalore' },
    { label: 'Chennai', value: 'Chennai' },
    { label: 'Hyderabad', value: 'Hyderabad' },
    { label: 'Kolkata', value: 'Kolkata' },
    { label: 'Gurgaon', value: 'Gurgaon' },
  ]

  const selectOption = (opt) => {
    setOptions(opt)
  }

  const selectTags = (tags) => {
    setTags(tags)
    setOptions([...tags, ...lang])
  }
  const selectLang = (lang) => {
    setLang(lang)
    setOptions([...lang, ...tags])
  }


  return (
    <div className="App text-center">

      <Routes>
        <Route path="/" element={<Dashboard />} />
        {/* <Route path="/jobs/:job-type?" element={<Jobs />} /> */}
        <Route path="/jobs" element={<Jobs />}>
          <Route path=":jobtype" element={<Jobs  />} />
          <Route index element={<Jobs />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;