// import logo from './logo.svg';
import './App.css';
import { useEffect, useState } from 'react';
import API from '@aws-amplify/api';
import logo from './image/odnlogo.png'
import Select from 'react-select';

import { RWebShare } from "react-web-share";

import JobCard from './JobCard'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { fab } from '@fortawesome/free-brands-svg-icons'
import { faBusinessTime, faCheckSquare, faCoffee, faLaptopHouse, faMapMarkerAlt, faRupeeSign, faShareAlt, faSun } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from './Components/Header/Header';
import { useParams } from 'react-router-dom';
import { REST_API_NAME } from './types';



function Jobs() {

    const [jobs, setJobs] = useState<any[]>([])
    const [options, setOptions] = useState<any[]>([]);
    const [lang, setLang] = useState([]);
    const [tags, setTags] = useState([]);

    const { jobtype } = useParams();



    useEffect(() => {
        console.log(jobtype);
        const promise = API.get(REST_API_NAME, '/jobs/', {})
            .then(r => {
                setJobs(r.records);

                //for now filter after all jobs are retreived
                //@todo filter on the server
                if (jobtype) {
                    const tags = JOB_TAGS.filter(j => j.value.toLowerCase() == jobtype);
                    tags.length && setOptions(tags)
                }

            })

        return () => {
            API.cancel(promise, "Canceling jobs request");
        }
    }, [])

    const JOB_TAGS = [
        { label: 'IT', value: 'IT' },
        { label: 'Non IT', value: 'NonIT' },
        { label: 'BPO', value: 'BPO' },
        { label: 'Voice', value: 'Voice' },
        { label: 'Non Voice', value: 'NonVoice' },
        { label: 'International Voice', value: 'InternationalVoice' },
        { label: 'Semi Voice', value: 'SemiVoice' },

        { label: 'Work From Home Until Further Notice', value: 'WFH' },
        { label: 'Work From Office', value: 'WFO' },
        { label: 'Hybrid Work Model', value: 'HybridWorkModel' },

        { label: 'Tech Support', value: 'TechSupport' },
        { label: 'Customer Support', value: 'CustomerSupport' },

        { label: 'Interior Designer', value: 'InteriorDesigner' },
        { label: 'Coding Teacher', value: 'CodingTeacher' },
        { label: 'Healthcare', value: 'Healthcare' },
        { label: 'Retail', value: 'Retail' },
        { label: 'Front Office Executive', value: 'FrontOfficeExecutive' },
        { label: 'Receptionist', value: 'Receptionist' },

        { label: 'Day Shift', value: 'DayShift' },
        { label: 'Night Shift', value: 'NightShift' },
        { label: 'US Shift', value: 'USShift' },
        { label: 'UK Shift', value: 'UKShift' },
        { label: 'Australia Shift', value: 'AustraliaShift' },
        { label: 'Rotational Shift', value: 'RotationalShift' },
        { label: 'Rotational Off', value: 'RotationalOff' },

        { label: 'Bangalore', value: 'Bangalore' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'Kolkata', value: 'Kolkata' },
        { label: 'Gurgaon', value: 'Gurgaon' },


        { label: 'Sales International & Domestic', value: 'Sales' },
        { label: 'Inside Sales, Pre Sales, Soft Sales', value: 'InsideSales' },
        { label: 'Field Sales', value: 'FieldSales' },
        { label: 'Cold Calling Sales', value: 'ColdCallingSales' },




        { label: 'Above 40 Years', value: 'Above40years' },
        { label: 'Crypto Currency', value: 'CryptoCurrency' },
        { label: 'Graduates Only', value: 'GraduatesOnly' },
        { label: 'Graduates', value: 'Graduates' },
        { label: 'Graduate With Experience', value: 'GraduateWithExperience' },
        { label: 'Under Graduates', value: 'UnderGraduates' },
        { label: 'Under Graduate Fresher', value: 'UnderGraduateFresher' },
        { label: 'Under Graduate With Experience', value: 'UnderGraduateExperienced' },
        { label: 'Fresher', value: 'Fresher' },
        { label: '10th Pass', value: '10thPass' },
        { label: '12th Pass', value: '12thPass' },
        { label: 'Weekend Off', value: 'WeekendOff' },
        { label: 'Domestic Process', value: 'DomesticProcess' },
        { label: 'Healthcare', value: 'Healthcare' },


        { label: 'No Career Gaps', value: 'NoGaps' },

        { label: 'Hindi Mandatory', value: 'Hindi' },
        { label: 'Gujarati Mandatory', value: 'Gujarati' },
        { label: 'Kannada Mandatory', value: 'Kannada' },
        { label: 'Malayalam Mandatory', value: 'Malayalam' },
        { label: 'Tamil Mandatory', value: 'Tamil' },
        { label: 'Telugu Mandatory', value: 'Telugu' },
        { label: 'Arabic Mandatory', value: 'Arabic' },

        { label: 'Regional Languages', value: 'RegionalLanguages' },

        { label: 'Laptop Mandatory', value: 'LaptopMandatory' },
        { label: 'No BE or Btech', value: 'NoBEorBtech' },

        { label: 'Females Only', value: 'FemalesOnly' },

        { label: 'Ops Managers', value: 'OpsManager' },
        { label: 'Team Leaders', value: 'TeamLeader' },

        { label: 'Lateral/ Above Agent Level Roles', value: 'Lateral' },

        { label: 'HR & Admin Roles', value: 'HRAndADMIN' },

        { label: 'BFSI/ Finance', value: 'BFSIOrFinance' },

        { label: 'Sales And Marketing', value: 'Sales-And-Marketing' },
        { label: 'Social Media Marketing/ Growth Hacker', value: 'smm-growth-hacker' },
        { label: 'Digital Marketing', value: 'digital-marketing' },
    ]

    const JOB_LANGS = [
        { label: 'Bangalore', value: 'Bangalore' },
        { label: 'Chennai', value: 'Chennai' },
        { label: 'Hyderabad', value: 'Hyderabad' },
        { label: 'Kolkata', value: 'Kolkata' },
        { label: 'Gurgaon', value: 'Gurgaon' },
    ]

    const selectOption = (opt: any) => {
        setOptions(opt)
    }
    const setJobTypeFilters = (opt: any) => {
        setOptions(opt);
        const optionsString = opt.map((o:any) => o.value).join(',');
        window.history.pushState(null, 'ODN Jobs', '/jobs/' + optionsString.toLowerCase())

    }

    const selectTags = (tags: any) => {
        setTags(tags)
        setOptions([...tags, ...lang])
    }
    const selectLang = (lang: any) => {
        setLang(lang)
        setOptions([...lang, ...tags])
    }

    return (
        <div className="App text-center">
            <Header />
            {/* <div>
                <img src={logo} className={'my-2'} />
            </div> */}
            <div className='row justify-content-center'>
                <div className='col-lg-6 col-md-12 col-sm-12' >
                    <Select
                        isMulti={true}
                        options={JOB_TAGS}
                        // onChange={selectOption}
                        onChange={setJobTypeFilters}
                        placeholder='Filter Jobs As Per Requirement'
                        value={options}
                    />
                    {/* <Select
            isMulti={true}
            options={JOB_LANGS}
            onChange={selectLang}
            placeholder='Filter Lang'
          />
          <Select
            isMulti={true}
            options={JOB_TAGS}
            onChange={selectTags}
            placeholder='Filter Jobs'
          /> */}
                </div>
            </div>

            <div>
                <div className="container">

                    <div className="row">
                        {/* <div className="col-lg-4 col-sm-12 job">
              <div className='card'>
                <div className='card-body'>
                  <div className='card-title title'>Hero Motor Corp: Data Engineer</div>
                  <div className='designation-experience'>
                    <div className='designation'>3-6 years</div>
                    <div className='experience'>12LPA</div>
                  </div>
                  <div className='designation-experience'>
                    <div className='designation'>WFO</div>
                    <div className='designation'>Day Shift</div>
                    <div className='experience'>Bangalore</div>
                  </div>


                  <div className='details m-2'>
                    <span className='value'>nsdkj nsk sjhdfgfdkjh dfjhgdfh dfkj hkdfjhdjk</span>
                  </div>
                </div>
                <div className='card-footer p-0'>



                  <div className="action-buttons" role="toolbar" aria-label="Toolbar with button groups">
                    <RWebShare
                      data={{
                        text: "Hero Motor Corp: Data Engineer",
                        url: "https://onedegreenorth.com/jobs/hero",
                        title: "Hero Motor Corp: Data Engineer",
                      }}
                      onClick={() => console.log("shared successfully!")}
                    >
                      <button type="button" className="btn btn-primary btn-lg">Share</button>
                    </RWebShare>
                    <button type="button" className="btn btn-success btn-lg">Apply</button>
                  </div>
                </div>

              </div>

            </div> */}


                    </div>
                </div>
            </div>
            <div className="container">

                <div className="row">
                    {jobs
                        .filter(j => {
                            return options.length ? options.some((opt) => (j.tags || '').indexOf("," + opt.value + ",") != -1) : true
                        })
                        .map((job, idx) => {
                            const { image_key } = job;
                            if (!image_key) {
                                // console.log(job);
                                return <JobCard key={`j-${idx}`} job={job} />
                            }


                            return <div className="col-lg-4 col-sm-12 job" key={`j-${idx}`}>
                                <img key={image_key} src={image_key} className={'my-5 d-block'} style={{ width: "100%", maxWidth: "500px", margin: "0 auto" }} />
                            </div>
                        })}
                    {/* {jobs.map(({ title,image_key,qualification }) => <div key={image_key}  className="card" style={{width:"100%",maxWidth: "500px"}}>
        <img src={image_key} className="card-img-top" alt="..." />
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{qualification}</p>
          <a href="#" className="btn btn-primary">Apply</a>
        </div>
      </div>)} */}

                </div>
            </div>
        </div>
    );
}

export default Jobs;
