// import logo from './logo.svg';

import { useEffect, useState } from 'react';



// import { fab } from '@fortawesome/free-brands-svg-icons'

import { Link } from 'react-router-dom';

import img1 from './assets/images/Building-Product.png';
import img2 from './assets/images/Coding.png';
import img3 from './assets/images/Creating-a-Web.png';
import img4 from './assets/images/Creating-Mobile-App.png';
import { UPDATE_FREQUENCY } from './types';

import logo from './assets/images/odnlogo.png'
import SubScribeModal from './Components/SubscribeModal/SubscribeModal';


function Dashboard() {

  const randomIntFromInterval = (min: number, max: number) => { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  const images = [img1, img2, img3, img4];

  useEffect(() => {
    setImg(images[randomIntFromInterval(0, images.length - 1)])
  }, [])

  const [updateFrequency, setUpdateFrequency] = useState<UPDATE_FREQUENCY>(UPDATE_FREQUENCY.DAILY)
  const [img, setImg] = useState<string>(images[randomIntFromInterval(0, images.length - 1)])





  return (
    <div className="h-100 d-flex flex-coloumn">
      <div className='dash-button-container1'>
        <img src={logo} className='m-5' />
      </div>

      <div className='row align-center justify-content-evenly m-2'>
        <div className='dash-button'>
          <Link to='/jobs/it'>View IT Jobs</Link>
        </div>
        <div className='dash-button'>
          <Link to='/jobs/bpo'>View BPO Jobs</Link>
        </div>
      </div>

      <div className='row align-center justify-content-evenly m-2'>
        <div className='dash-button'>
          <Link to='/jobs/nonit'>View Non IT Jobs</Link>
        </div>

        <div className='dash-button'>
          <Link to='/jobs/bfsiorfinance'>View BFSI/ Finance Jobs</Link>
        </div>
      </div>

      <div className='row align-center justify-content-evenly m-2'>
        <div className='dash-button'>
          <Link to='/jobs/sales-and-marketing'>Sales & Marketing</Link>
        </div>

        <div className='dash-button'>
          <Link to='/jobs/smm-growth-hacker'>SMM or Growth Hacker</Link>
        </div>
      </div>

      <div className='row align-center justify-content-evenly m-2'>
        <div className='dash-button'>
          <Link to='/jobs/digital-marketing'>Digital Marketing</Link>
        </div>

        <div className='dash-button'>
          <a type="button" className="btn btn-link" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            Subscribe
          </a>
        </div>
        <SubScribeModal />

      </div>

    </div>
  );
}

export default Dashboard;
