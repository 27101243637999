import { API } from "aws-amplify";
import { useState } from "react";
import { REST_API_NAME, UPDATE_FREQUENCY } from "../../types";

const SubScribeModal = () => {


  const [updateFrequency, setUpdateFrequency] = useState<UPDATE_FREQUENCY>(UPDATE_FREQUENCY.DAILY)
  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')


  const validateEmail = (email:string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };


  const subscribe=()=>{

    const user={
        frequency:updateFrequency,
        email,
        phone
    }

    if(!email || !phone){
        alert('Email and Phone are necessary to send notifications');
        return;
    }
    if(!validateEmail(email)){
        alert('Please enter valid email address');
        return;
    }
    // if(phone.match(/^(\+\d{1,3}[- ]?)?\d{10}$/) && ! (phone.match(/0{5,}/)) ){
    //     alert('Please enter valid Phone number');
    //     return;
    // }
    if(!(phone.length>=10 && phone.length<=12)){
        alert('Please enter valid Phone number');
        return;
    }

    const promise = API.post(REST_API_NAME, '/subscriber/', {
        body:user
    })
    .then(r => {
        alert('Hey, Thanks for subscribing. Please do check your inbox post March 15th for regular updates.');
        window.location.reload();
       

    })


  }

  
    return (<div className="modal" tabIndex={-1} id="staticBackdrop">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title">Subscribe</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <h3>Subscribe to get updates.</h3>
                    <div className="card" >
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                <div className="form-check form-switch">
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Daily Updates</label>
                                    <input className="form-check-input" type="radio" role="switch" id="flexSwitchCheckChecked" onChange={(evt) => setUpdateFrequency(evt.target.checked ? UPDATE_FREQUENCY.DAILY : updateFrequency)} checked={updateFrequency == UPDATE_FREQUENCY.DAILY} />
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-check form-switch">
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Weekly Updates</label>
                                    <input className="form-check-input" type="radio" role="switch" id="flexSwitchCheckChecked" onChange={(evt) => setUpdateFrequency(evt.target.checked ? UPDATE_FREQUENCY.WEEKLY : updateFrequency)} checked={updateFrequency == UPDATE_FREQUENCY.WEEKLY} />
                                </div>
                            </li>
                            <li className="list-group-item">
                                <div className="form-check form-switch">
                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Twice a Month</label>
                                    <input className="form-check-input" type="radio" role="switch" id="flexSwitchCheckChecked" onChange={(evt) => setUpdateFrequency(evt.target.checked ? UPDATE_FREQUENCY.FORTNIGHTLY : updateFrequency)} checked={updateFrequency == UPDATE_FREQUENCY.FORTNIGHTLY} />
                                </div>
                            </li>

                            <li className="list-group-item">
                                <div className="input-group mb-3">
                                    <span className="input-group-text">
                                        <i className="bi bi-telephone-fill"></i>

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                                            <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                        </svg>

                                    </span>
                                    <input type="text" className="form-control" aria-label="Phone" onBlur={(evt)=>setPhone(evt.target.value)} />
                                </div>

                                <div className="input-group mb-3">
                                    <span className="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                                            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                        </svg>
                                    </span>
                                    <input type="email" className="form-control" aria-label="Email" onBlur={(evt)=>setEmail(evt.target.value)} />
                                </div>
                            </li>
                            {/* <li className="list-group-item">
                <button className='btn btn-success btn-md' onClick={() => alert('Please check back soon')}>Subscribe</button>
              </li> */}

                        </ul>
                    </div>
                </div>
                <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-success" onClick={subscribe}>Subscribe</button>
                </div>
            </div>
        </div>
    </div>)
}
export default SubScribeModal;