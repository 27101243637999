import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { RWebShare } from "react-web-share"


interface JobInterface {
    id: number
    company_id: number
    tags: string
    image_key: string
    designation: string
    communication: string
    title: string
    qualification: string
    experience: string
    jd: string
    salary: string;
    shift: string;
    location: string;
    work_type: string;
    is_active: 1 | 0

}

const JobCard = ({ job }: { job: JobInterface }) => {



    return (<div className="col-lg-4 col-sm-12 job">
        <div className='card'>
            <div className='card-body'>
                <div className='card-title title'>{`${job.title}: ${job.designation}`}</div>
                <div className='designation-experience'>
                    <div className='designation'><FontAwesomeIcon icon="business-time" />{job.experience}</div>
                    <div className='experience'><FontAwesomeIcon icon="rupee-sign" />{job.salary}</div>
                {/* </div>
                <div className='designation-experience'> */}
                    {job.work_type?<div className='designation wt'><FontAwesomeIcon icon="laptop-house" />{job.work_type}</div>:''}
                    {job.shift?<div className='designation'><FontAwesomeIcon icon="sun" />{job.shift}</div>:''}
                    {job.location?<div className='experience'><FontAwesomeIcon icon="map-marker-alt" />{job.location}</div>:''}
                </div>

                <hr />

                <div className='details m-2'>
                    <span className='value text-start' dangerouslySetInnerHTML={{ __html: job.jd }}></span>
                </div>
            </div>
            <div className='card-footer p-0'>

                <div className="action-buttons" role="toolbar" aria-label="Toolbar with button groups">
                    {/* <RWebShare
                        data={{
                            text: "ODN",
                            url: "https://onedegreenorth.com/jobs/hero",
                            title: "ODN",
                        }}
                        onClick={() => console.log("shared successfully!")}
                    >
                        <button type="button" className="btn btn-primary btn-lg">
                            <FontAwesomeIcon icon={'share-alt'} />
                        </button>
                    </RWebShare> */}
                    {/* <button type="button" className="btn btn-success btn-lg">Apply</button> */}
                </div>
            </div>

        </div>

    </div>
    )
}
export default JobCard;