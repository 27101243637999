import logo from '../../assets/images/odnlogo.png'

const Header=()=>{



    return (<div>
        <img src={logo} className={'my-2'} />
      </div>)
}


export default Header;